/* eslint-disable react/prop-types */
import { Dialog, DialogContent, DialogTitle, Box, Slide, Typography } from "@mui/material";
import React from "react";
import { LoginForm } from '../../sections/auth/login';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export default function LoginDialog({ component, isOpen }) {
    const [open, setOpen] = React.useState(isOpen || false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {component && React.cloneElement(component, { onClick: handleOpen })}
            
            <Dialog
                open={open}
                fullWidth maxWidth='xs'
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                titleStyle={{ textAlign: "center" }}
            >
                <DialogTitle sx={{ mb: 4 }} >
                    <Typography variant="h5" align="center">SIGN IN</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ p: 1 }} >
                        <LoginForm cancel={handleClose} />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
