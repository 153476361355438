import PropTypes from 'prop-types';

// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Autocomplete, Box  } from '@mui/material';
import countries from '../../utils/country'

// ----------------------------------------------------------------------

RHFCountryField.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default function RHFCountryField({ name, defaultValue, ...other }) {
  const { control } = useFormContext();
  // const [defaut, setDefaut] = useState({})

  // useEffect(() => {
  //   const fc = countries.find(r => r.label === defaultValue.trim());
  //   console.log(fc)
  //   setDefaut(fc)
  // }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
        <Autocomplete
          fullWidth
          defaultValue={countries.find(r => r.label === defaultValue?.trim())}
          options={countries}
          error={!!error} helperText={error?.message} {...other}
          onChange={(x, obj) => onChange(obj?.label)}
          onBlur={onBlur}
          autoHighlight
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.label === value.label}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img loading="lazy" width="20" alt=""
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled',
              }}
            />
          )}
        />
      )}
    />
  );
}