/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { createContext, useEffect, useReducer } from 'react';
import { useMutation,useQuery } from "@apollo/client";

import PropTypes from 'prop-types';
import { isValidToken, setSession } from '../utils/jwt';
import { LOGIN, REGISTER, QUERY_ME} from '../graphql/userApi'


// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => 
    // const { user } = action.payload;
     ({
      ...state,
      isAuthenticated: false,
      user: null,
    })
  ,
  F5: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  f5:() => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loginFunc] = useMutation(LOGIN);
  const [registerFunc] = useMutation(REGISTER);
  const { loading, error, data, refetch } = useQuery(QUERY_ME, { pollInterval: 3000 });
  // const {loading, error, data, refetch} = useQuery(QUERY_ME);
  
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user:data?.me,
            },
          });
        } else {
          
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [data]);

  const login = async (email, password, onError) => {
    loginFunc({
      variables: { input: { email, password } },
      onCompleted: async (data) => {
        if (data.login.error !== 0) {
          // console.log(data.error.message)
        } else {
          const { access_token, user } = data.login;
          setSession(access_token);
          dispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          });
        }
      },
      onError: (e) =>{onError(e)}
    });
  };

  const register = async (inp, onError, onCompleted) => {
    registerFunc({
      variables: { input: inp },
      onCompleted: async (data) => {
        if (data.register.error !== 0) {
          // console.log(data.register.message)
        } else {
          // window.localStorage.setItem('accessToken', accessToken);
          dispatch({
            type: 'REGISTER',
            payload: {
              // user,
            },
          });
          onCompleted()
        }
      },
      onError: (e) =>{onError(e)}
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const f5 = async () => {
    refetch();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        f5
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
