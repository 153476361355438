import './App.css';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components

import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { DialogsProvider } from './contexts/DialogsContext';
import { FilterQuestionProvider } from './contexts/FilterQuestionContext';
import { UserTableProvider } from './contexts/UserTableContext';
import { ExamProvider } from './contexts/ExamContext';


// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <ExamProvider>
              <DialogsProvider>
                <NotistackProvider>
                  <MotionLazyContainer>
                    <ProgressBarStyle />
                    <ChartStyle />
                    <Settings />
                    <ScrollToTop />
                    <FilterQuestionProvider>
                      <UserTableProvider>
                        <Router />
                      </UserTableProvider>
                    </FilterQuestionProvider>
                  </MotionLazyContainer>
                </NotistackProvider>
              </DialogsProvider>
            </ExamProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
