/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { createContext } from 'react';
// hooks
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultDt = {
    title: '',
    rule: '',
    total_time: 0,
    start_time: null,
    status: [2,2],
    type: 1,
    parts: [], 
    // {
    //     description,
    //     questions,
    //     time,
    // }
}

const initialState = {
    ...defaultDt,
    onChangeType: () => { },
    onChangeStatus: () => { },
    onChangeTitle: () => { },
    onChangeRule: () => { },
    onStartTime: () => { },
    onTotalTime: () => { },
    onChangePartDescription: () => { },
    onChangePartQuestions: () => { },
    onChangePartTime: () => { },
    onRemovePart: () => { },
    onPushPart: () => { },
    onPopPart: () => { },
    onPushQ: () => { },
    onRemoveQ: () => { },
};

const ExamContext = createContext(initialState);

// ----------------------------------------------------------------------

ExamProvider.propTypes = {
  children: PropTypes.node,
};


function ExamProvider({ children }) {

    const [exam, setExam] = useMemoryState('create_exam', {
        title: initialState.title,
        rule: initialState.rule,
        total_time: initialState.total_time,
        start_time: initialState.start_time,
        parts: initialState.parts,
        status: initialState.status,
        type: initialState.type,
    });

    const onInit = (state) => {
        setExam({
            ...exam,
            title: state.title,
            rule: state.rule,
            total_time: state.total_time,
            start_time: state.start_time,
            status: state.status,
            type: state.type,
            parts: state.parts,
        });
    };

    const onChangeTitle = (state) => {
        setExam({
            ...exam,
            title: state,
        });
    };

    const onChangeRule = (state) => {
        setExam({
            ...exam,
            rule: state,
        });
    };


    const onChangeType = (state) => {
        setExam({
            ...exam,
            type: state,
        });
    };

    const onChangeStatus = (state) => {
        setExam({
            ...exam,
            status: state,
        });
    };

    const onChangePartDescription = (index, state) => {
        const arrPartCopy = [...exam.parts];
        arrPartCopy[index] = { ...exam.parts[index], description: state };
        setExam({
            ...exam,
            parts: arrPartCopy,
        });
    };

    const onChangePartTime = (index, state) => {
        const arrPartCopy = [...exam.parts];
        arrPartCopy[index] = { ...exam.parts[index], time: state };
        setExam({
            ...exam,
            parts: arrPartCopy,
        });
    };

    const onChangePartQuestions = (index, state) => {
        const arrPartCopy = [...exam.parts];
        arrPartCopy[index] = { ...exam.parts[index], questions: state };
        setExam({
            ...exam,
            parts: arrPartCopy,
        });
    };

    const onRemovePart = (index) => {
        const arrPartCopy = [...exam.parts].filter((item, i) => (i !== index))
        setExam({
            ...exam,
            parts: arrPartCopy
        });
    };

    const onTotalTime = (state) => {
        setExam({
            ...exam,
            total_time: state,
        });
    };

    const onStartTime = (state) => {
        setExam({
            ...exam,
            start_time: state,
        });
    };

    const onPushPart = () => {
        setExam({
            ...exam,
            parts: [...exam.parts, { }]
        });
    };

    const onPopPart = () => {
        setExam({
            ...exam,
            parts:exam.parts.slice(0, exam.parts.length - 1)
        });
    };

    const onPushQ = (index, question_id) => {
        const arrPartCopy = [...exam.parts];
        arrPartCopy[index] = { ...exam.parts[index], questions: [...exam.parts[index].questions, { id: question_id }] };
        setExam({
            ...exam,
            parts: arrPartCopy
        });
    };

    const onRemoveQ = (index, question_id) => {
        const arrPartCopy = [...exam.parts];
        arrPartCopy[index] = { ...exam.parts[index], questions: exam.parts[index].questions.filter(item => item.id !== question_id) };
        setExam({
            ...exam,
            parts: arrPartCopy
        });
    };

    return (
        <ExamContext.Provider
            value={{
                ...exam,
                onInit,
                onChangeTitle,
                onChangeType,
                onChangeStatus,
                onChangeRule,
                onChangePartQuestions,
                onChangePartTime,
                onChangePartDescription,
                onRemovePart,
                onTotalTime,
                onStartTime,
                onPushPart,
                onPopPart,
                onPushQ, 
                onRemoveQ
            }}
        >
            {children}
        </ExamContext.Provider>
    );
}

export { ExamProvider, ExamContext }
