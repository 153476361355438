import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, single = false }) {
  
        
  const logo = (
    <Box sx={{
      width: single ? 60 : 170, ...sx
    }}>
      <img src={single ? `/logo/logo_single.svg` : `/logo/logo_full.svg`} alt="i" loading="lazy" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
