/* eslint-disable react/prop-types */
// @mui
import { Box, Typography, Stack } from '@mui/material';
// ----------------------------------------------------------------------

export default function BlockContent({ hass }) {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      direction='row'
      sx={{ width: 1, textAlign: 'center', px: 1 }}
    >
      {/* <UploadIllustration sx={{ width: 180 }} /> */}

      <Box >
        <Typography gutterBottom variant="h6" color={hass ? 'success.main' : 'text.main'}>
          {hass ? 'Certificate uploaded' : 'Drop or Select certificate file'}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
        </Typography>
      </Box>
    </Stack>
  );
}
