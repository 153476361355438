/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_ADMIN } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  if (!isInitialized) {
    return <LoadingScreen />;
  }
  if (isAuthenticated && user) {
    if (user.type === 1) {
      return <Navigate to={PATH_ADMIN.root} />
    }
    return <Navigate to={PATH_DASHBOARD.root} />
  }
  return <>{children}</>;
}
