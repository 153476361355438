// routes
import { PATH_DASHBOARD, PATH_ADMIN } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------


const icon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  {
    items: [
      { title: 'Exam', path: PATH_DASHBOARD.general.exam, icon: ICONS.dashboard },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'profile',
        path: PATH_DASHBOARD.user.profile,
        icon: ICONS.user,
      },
      {
        title: 'Exam history',
        path: PATH_DASHBOARD.user.reports,
        icon: ICONS.invoice,
      },
    ],
  },
];

const navConfigAdmin = [
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        path: PATH_ADMIN.management.users.list,
        icon: ICONS.user,
        // children: [
        //   { title: 'list', path: PATH_ADMIN.management.users.list},
        // ],
      },
      {
        title: 'exam',
        path: PATH_ADMIN.management.exam.root,
        icon: ICONS.file,
        children: [
          { title: 'info', path: PATH_ADMIN.management.exam.info },
          { title: 'create', path: PATH_ADMIN.management.exam.create},
        ],
      },
      {
        title: 'question',
        path: PATH_ADMIN.management.questions.root,
        icon: ICONS.mail,
        children: [
          { title: 'create', path: PATH_ADMIN.management.questions.create},
          { title: 'list', path: PATH_ADMIN.management.questions.list },
        ],
      },
    ],
  },
  {
    items: [
      { title: 'Monitoring', path: PATH_ADMIN.monit, icon: ICONS.dashboard },
    ],
  },
];

export { navConfig, navConfigAdmin };
