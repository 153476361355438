import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultFilter = {
    page: 1,
    count: 9*5,
    type: 0,
    tag: null,
    is_publish: 2,
    totalRow: 0,
}

const initialState = {
    ...defaultFilter,
    onChangePage: () => { },
    onChangeCount: () => { },
    onChangeType: () => { },
    onChangeTag: () => { },
    onChangePublish: () => { },
    onChangeTotalRow: () => { }
};

const FilterQuestionContext = createContext(initialState);

// ----------------------------------------------------------------------

FilterQuestionProvider.propTypes = {
  children: PropTypes.node,
};
        
function FilterQuestionProvider({ children }) {

    const [filterSetting, setFilterSetting] = useMemoryState('filterQuestionSetting', {
        page: initialState.page,
        count: initialState.count,
        type: initialState.type,
        tag: initialState.tag,
        is_publish: initialState.is_publish,
        totalRow: initialState.totalRow,
    });

    const onChangeCount = (state) => {
        setFilterSetting({
            ...filterSetting,
            count: state,
        });
    };
    
    const onChangePage = (state) => {
        setFilterSetting({
            ...filterSetting,
            page: state,
        });
    }
        
    const onChangeType = (state) => {
        setFilterSetting({
            ...filterSetting,
            type: state,
            
        });
    };
    
    const onChangeTag = (state) => {
        setFilterSetting({
            ...filterSetting,
            tag: state,
            
        });
    };

    const onChangePublish = (state) => {
        setFilterSetting({
            ...filterSetting,
            is_publish: state,
        });
    };

    const onChangeTotalRow = (state) => {
        setFilterSetting({
            ...filterSetting,
            totalRow: state,
        });
    };

    return (
        <FilterQuestionContext.Provider
            value={{
                ...filterSetting,
                onChangePage,
                onChangeCount,
                onChangeType,
                onChangeTag,
                onChangePublish,
                onChangeTotalRow
            }}
        >
            {children}
        </FilterQuestionContext.Provider>
    );
}
export { FilterQuestionProvider, FilterQuestionContext }
