/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, DesktopDatePicker  } from '@mui/x-date-pickers';
// ----------------------------------------------------------------------

RHFPickDate.propTypes = {
  name: PropTypes.string,
};

export default function RHFPickDate({ name, onChange, pickType, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} />
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          {pickType === 'DateTimePicker' ?
            <DateTimePicker
              {...field} fullWidth error={!!error} helperText={error?.message} {...other}
              onChange={(event) => {
                field.onChange(event)
                onChange && onChange(event)
              }}
              // label="Date desktop"
              inputFormat="DD MMM YYYY, hh:mm"
              // value={value}
              // onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            /> :
            <DesktopDatePicker
              {...field} fullWidth error={!!error} helperText={error?.message} {...other}
              onChange={(event) => {
                field.onChange(event)
                onChange && onChange(event)
              }}
              // label="Date desktop"
              // inputFormat="DD MMM YYYY, hh:mm"
              // value={value}
              // onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          }
          </LocalizationProvider>
        </>
      )}
    />
  )
}
