import gql from 'graphql-tag';

export const SERVER_TIME = gql`
query {
  server_time 
}`;

export const EXAM_CUR = gql`
query {
  exam_cur 
}`;

export const TAGS = gql`
query {
  tags 
}`;

export const LANGUAGES = gql`
query {
  languages {
    id
    language
  }
}`;

export const LANGUAGES_EXAM = gql`
query languages_exam($id:ID!){
  languages_exam(id:$id) {
    id
    language
  }
}`;

export const QUERY_ME = gql`
query {
  me {
    id
    type
    email
    profile_pic
    mobile_number
    first_name
    last_name
    city
    country
    agency
    position
    birth_day
    state
    is_verify
    is_ready_exam
    tick
    has_certificate
    public_result
    answer {
      axam_id
      question_index
      part
      question_id
      language_id
      answer
      status
    }
  }
}`;

export const QUERY_USERS = gql`
query {
  users {
    id
    type
    email
    profile_pic
    mobile_number
    first_name
    last_name
    city
    country
    agency
    position
    birth_day
    state
    is_verify
    is_ready_exam
    tick
    has_certificate
    public_result
  }
}`;

export const QUERY_USER = gql`
query user($id:ID!) {
  user(id:$id) {
    id
    type
    email
    profile_pic
    mobile_number
    first_name
    last_name
    city
    country
    agency
    position
    birth_day
    state
    is_verify
    is_ready_exam
    tick
    has_certificate
    public_result
  }
}`;

export const QUERY_USERS_EXAM = gql`
query users_exam($id:ID!, $exam_id: ID!) {
  users_exam(id:$id, exam_id:$exam_id) {
    title
    start_time
    total_time
    parts
  }
}`;

export const QUERY_EXAM_COMMON = gql`
query exam_info_common {
  exam_info_common {
    exam_title
    exam_time
    exam_date
    exam_date_detail
  }
}`;

export const QUERY_EXAM = gql`
query exam($id:ID!) {
  exam(id:$id) {
    id
    type
    title
    rule
    start_time
    total_time
    parts
    status
    created_at
  }
}`;

export const QUERY_EXAM_TINY = gql`
query exam_tiny($id:ID!) {
  exam_tiny(id:$id) {
    id
    type
    title
    total_questions
    start_time
    total_time
    status
  }
}`;

export const LOGIN = gql`
mutation login ($input: LoginInput!){
  login (input: $input) {
    error
    message
  	access_token
    user {
      type
      email
      profile_pic
      mobile_number
      first_name
      last_name
      city
      country
      agency
      position
      birth_day
      state
      is_verify
      is_ready_exam
      tick
      has_certificate
      public_result
    }
  }
}`;

export const REGISTER = gql`
mutation register ($input: RegisterInput!){
  register (input: $input) {
    error
    message
  }
}`;

export const UPDATE_PROFILE = gql`
mutation update_profile ($input: UpdateInput!){
  update_profile (input: $input) {
    error
    message
  }
}`;

export const READY_EXAM = gql`
mutation ready_exam ($isOK: Int!){
  ready_exam (isOK: $isOK) {
    error
    message
  }
}`;

export const PAUSER_EXAM = gql`
mutation exam_pause {
  exam_pause (isOk:1) {
    error
    message
  }
}`;

export const CREATE_QUESTION = gql`
mutation create_question ($input: QuestionInput!){
  create_question (input: $input) {
    error
    id
    message
  }
}`;

export const UPDATE_QUESTION = gql`
mutation update_question ($input: QuestionInput!){
  update_question (input: $input) {
    error
    message
  }
}`;

export const QUERY_QUESTIONS = gql`
query questions($input: QueryQuestion!) {
  questions(input: $input) {
    error
    count
    questions {
      id
      type
      tag
      languages { 
        language
      }
      is_publish
      update_at
    }
  }
}`;

export const QUERY_QUESTIONS_ALL = gql`
query {
  questions_all {
    id
    tag
  }
}`;

export const QUERY_QUESTION = gql`
query question($id: ID!) {
  question(id: $id) {
    id
    type
    tag
    is_publish
    update_at
    languages { 
      language
    }
    contents {
      language_id
      content
      options
      max_word
    }
  }
}`;

export const QUERY_QUESTION_CONTENT = gql`
query question_content($question_ids: [ID], $language_id: ID) {
  question_content(question_ids: $question_ids, language_id: $language_id) {
    question_id
    language_id
    type
    content
    options
    max_word
  }
}`;

export const APPROVE_EXAM = gql`
mutation approve_exam ($ids: [ID]){
  approve_exam (ids: $ids) {
    error
    message
  }
}`;

export const PUBLIC_RESULT = gql`
mutation public_result ($ids: [ID]){
  public_result (ids: $ids) {
    error
    message
  }
}`;

export const ADD_LANGUAGE = gql`
mutation add_language ($language: String){
  add_language (language: $language) {
    error
    message
  }
}`;

export const DELETE_QUESTION = gql`
mutation delete_question ($id: ID!){
  delete_question (id: $id) {
    error
    message
  }
}`;

export const DELETE_USERS = gql`
mutation delete_users ($ids: [ID]){
  delete_users (ids: $ids) {
    error
    message
  }
}`;

export const RESET_PASSWORD = gql`
mutation reset_password ($email: String!){
  reset_password (email: $email) {
    error
    message
  }
}`;

export const NEW_PASSWORD = gql`
mutation new_password ($input: ChangePasswordInput!){
  new_password (input: $input) {
    error
    message
  }
}`;

export const SAVE_EXAM = gql`
mutation save_exam ($input: ExamInput!){
  save_exam (input: $input) {
    error
    message
  }
}`;

export const BEGIN_EXAM = gql`
mutation exam_begin($id: ID!, $lang_id: ID!, $browser: String, $os: String) {
  exam_begin(id: $id, lang_id: $lang_id, browser: $browser, os: $os) {
    id
    error
    message
  }
}`;

export const ACTION_EXAM = gql`
mutation exam_action($input: [ExamActionInput]!) {
  exam_action (input: $input) {
    error
    message
  }
}`;

export const BEACON_EXAM = gql`
mutation exam_beacon($id:ID!, $state: Int!, $browser: String, $os: String) {
  exam_beacon (id: $id, state: $state, browser: $browser, os: $os,) {
    id
    error
  }
}`;

export const SUB_ILLEGAL_EXAM = gql`
mutation exam_sub_illegal($id:ID!, $user_id: ID!) {
  exam_sub_illegal (id: $id, user_id: $user_id) {
    id
    error
  }
}`;

export const MONIT_EXAM = gql`
query exam_monit($id:ID) {
  exam_monit(id:$id) {
    user_id
    profile_pic
    first_name
    last_name
    exam_id
    state
    violation_count
    browser
    os
  }
}`;

export const FINISH_EXAM = gql`
mutation exam_finish($id: ID!) {
  exam_finish (id: $id) {
    error
    message
  }
}`;

export const ADMIN_TICK = gql`
mutation admin_tick($id: ID!, $tick: Int!) {
  admin_tick (id: $id, tick: $tick) {
    error
    message
  }
}`;

export const UPDATE_EXAM_COMMON= gql`
mutation update_exam_info_common($input: ExamInfoCommonInput!){
  update_exam_info_common (input: $input) {
    error
  }
}`;

export const SET_ADMIN= gql`
mutation set_admin($input: SetAdminInput!){
  set_admin (input: $input) {
    error
  }
}`;
