export const BASE_API = "https://api.mda-examination.com"
export const GRAPH_API = `${BASE_API}/graphql`;
export const UPLOAD_API = `${BASE_API}/upload`;

// export const BASE_API = "http://localhost:5000"
// export const GRAPH_API = `${BASE_API}/graphql`;


// export const BASE_API = "https:///api-test.metabomb.io"
// export const GRAPH_API = `${BASE_API}/graphql`;

// export const BASE_API = "http:///45.77.41.218:81"
// export const GRAPH_API = `${BASE_API}/graphql`;

// export const BASE_API = "http://45.77.41.218:81";
// export const GRAPH_API = `${BASE_API}/graphql`;

// export const BASE_API = "https://api-testnet.metabomb.io";
// export const GRAPH_API = `${BASE_API}/graphql`;

// export const BASE_API = "https://api.metabomb.io";
// export const GRAPH_API = `${BASE_API}/graphql`;

export const linkToHome = "/";

export const linkToMarket = "/trade/heroes";

export const linkToAccount = "/account/settings";

export const linkToInventory = "/account/inventory";

export const linkToClaim = "/account/claim";

export const linkToActivites = "/account/activity";
