import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import { PATH_ADMIN } from './paths'
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'new-password',
          children: [
            { path: ':ticket', element: <NewPassword /> }
          ]
        },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },

    {
      path: 'exam',
      children: [
        {
          path: ':examToken', element:
            <AuthGuard isAdmin={false}>
              <ExamForm />
            </AuthGuard>
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard isAdmin={false}>
          <DashboardLayout />
        </AuthGuard> 
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'exam', element: <GeneralApp /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserCreate /> },
            { path: 'reports', element: <InvoiceDetails /> },
          ],
        },
      ],
    },

    {
      path: 'admin',
      element: (
        <AuthGuard isAdmin>
          <DashboardLayout />
        </AuthGuard> 
      ),
      children: [
        { element: <Navigate to={PATH_ADMIN.monit} replace />, index: true },
        { path: 'monit', element: <Monit /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':id/:p', element: <UserDetail /> },
          ],
        },
        {
          path: 'question',
          children: [
            { element: <Navigate to="/admin/question/create" replace />, index: true },
            { path: 'create', element: <QuestionCreate /> },
            { path: 'list', element: <QuestionList /> },
            { path: ':id', element: <QuestionUpdate /> },
          ],
        },
        {
          path: 'exam',
          children: [
            { element: <Navigate to="/admin/exam/create" replace />, index: true },
            { path: 'create', element: <ExamCreate /> },
            { path: 'info', element: <ExamCommonInfo /> },
          ],
        },
      ],
    },


    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/home" replace />, index: true },
        { path: 'home', element: (<GuestGuard> <Home /> </GuestGuard>) },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
    // { path: 'test', element: <Tesst/> },

  ]);
}

// const Tesst = Loadable(lazy(() => import('../pages/user/details/Tesst_')))
// AUTHENTICATION
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserDetail = Loadable(lazy(() => import('../pages/admin/UserDetail')));
const Home = Loadable(lazy(() => import('../pages/Home')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Monit = Loadable(lazy(() => import('../pages/admin/Monit')));
const UserList = Loadable(lazy(() => import('../pages/admin/UserList')));
const QuestionCreate = Loadable(lazy(() => import('../pages/admin/QuestionCreate')));
const QuestionUpdate = Loadable(lazy(() => import('../pages/admin/QuestionUpdate')));
const QuestionList = Loadable(lazy(() => import('../pages/admin/QuestionList')));
const ExamCreate = Loadable(lazy(() => import('../pages/admin/ExamCreate')));
const ExamForm = Loadable(lazy(() => import('../pages/admin/ExamForm')));
const ExamCommonInfo = Loadable(lazy(() => import('../pages/admin/ExamCommonInfo')));


// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));