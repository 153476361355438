import PropTypes from 'prop-types';
import { createContext } from 'react';

// hooks
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultTable = {
    dense: false,
    orderBy: 'name',
    order: 'asc',
    page: [0,0,0,0,0,0,0,0],
    rowsPerPage: 10,
    selected: [],
    currentTab: 'all'
}

const initialState = {
    ...defaultTable,
    onSort: () => { },
    onSelectRow: () => { },
    onSelectAllRows: () => { },
    onChangePage: () => { },
    onChangeRowsPerPage: () => { },
    onChangeDense: () => { },
    onChangeCurrentTab: () => { }
};


const UserTableContext = createContext(initialState);

// ----------------------------------------------------------------------

UserTableProvider.propTypes = {
  children: PropTypes.node,
};


function UserTableProvider({ children }) {

    const [userTable, setUserTable] = useMemoryState('userTable', {
        dense: initialState.dense,
        orderBy: initialState.orderBy,
        order: initialState.order,
        page: initialState.page,
        rowsPerPage: initialState.rowsPerPage,
        selected: initialState.selected,
        currentTab: initialState.currentTab,
    });
    

    const onSort = (state) => {
        const isAsc = userTable.orderBy === state && userTable.order === 'asc';
        if (state !== '') {
            setUserTable({
                ...userTable,
                order: isAsc ? 'desc' : 'asc',
                orderBy: state
            });
        }
    };
//   const onSelectRow = (id) => {
//     const selectedIndex = selected.indexOf(id);

//     let newSelected = [];

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, id);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
//     }
//     setSelected(newSelected);
//   };
    
    const onSelectRow = (state) => {
        const selectedIndex = userTable.selected.indexOf(state);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(userTable.selected, state);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(userTable.selected.slice(1));
        } else if (selectedIndex === userTable.selected.length - 1) {
            newSelected = newSelected.concat(userTable.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(userTable.selected.slice(0, selectedIndex), userTable.selected.slice(selectedIndex + 1));
        }
        setUserTable({
            ...userTable,
            selected: newSelected,
        });
    };

    const onSelectAllRows = (checked, newSelecteds) => {
        
        if (checked) {
            setUserTable({
                ...userTable,
                selected: newSelecteds,
            });
            return;
        }
        setUserTable({
            ...userTable,
            selected: [],
        });
    };

    const onChangePage = (event, newPage,search) => {
        const tabs = { 'all': 0, 'ready': 1, 'pending': 2, 'no attempted': 3, 'under review': 4, 'passed': 5 , 'unsuccessful': 6 }
        const copy = [...userTable.page];
        copy[search ? 7 : tabs[userTable.currentTab]] = newPage;
        // copy[tabs[userTable.currentTab]] = newPage;

        return setUserTable({
            ...userTable,
            page: copy,
        });
    };

    const onChangeRowsPerPage = (event,search) => {      
        const tabs = { 'all': 0, 'ready': 1, 'pending': 2, 'no attempted': 3, 'under review': 4, 'passed': 5, 'unsuccessful': 6 }
        const copy = [...userTable.page];
        copy[search ? 7 : tabs[userTable.currentTab]] = 0;
        // copy[tabs[userTable.currentTab]] = 0;
        return setUserTable({
            ...userTable,
            rowsPerPage: parseInt(event.target.value, 10),
            page: copy
        });
    };
    
    const onChangeDense = (event) => {
        setUserTable({
            ...userTable,
            selected: event.target.checked,
        });
    };
    
    const onChangeCurrentTab = (event, state) => {
        setUserTable({
            ...userTable,
            currentTab: state,
        });
    };

    return (
        <UserTableContext.Provider
            value={{
                ...userTable,
                onSort,
                onSelectRow,
                onSelectAllRows,
                onChangePage,
                onChangeRowsPerPage,
                onChangeDense,
                onChangeCurrentTab
            }}
        >
            {children}
        </UserTableContext.Provider>
    );
}

export { UserTableProvider, UserTableContext }
