import PropTypes from 'prop-types';
import { createContext } from 'react';
import { Button,Dialog, DialogTitle, DialogContent, DialogActions,Stack, Typography } from '@mui/material';

// hooks
import { useMemoryState } from '../utils/memoryState';

// ----------------------------------------------------------------------

const defaultDialog = {
    title: '',
    content: '',
    open: false,
    button: null,
    size: 'xs'
}

const initialState = {
    ...defaultDialog,
    onChangeTitle: () => { },
    onChangeContent: () => { },
    onOpen: () => { },
    setButton: () => { },
    noti:() => { }
};

const DialogsContext = createContext(initialState);

// ----------------------------------------------------------------------

DialogsProvider.propTypes = {
  children: PropTypes.node,
};


function DialogsProvider({ children }) {

    const [dialogs, setDialogs] = useMemoryState('dialogs', {
        size: initialState.size,
        title: initialState.title,
        content: initialState.content,
        button: initialState.button,
        open: initialState.open,
    });

    const onChangeTitle = (state) => {
        setDialogs({
            ...dialogs,
            title: state,
        });
    };

    const onChangeContent = (state) => {
        setDialogs({
            ...dialogs,
            content: state,
        });
    };

    const onOpen = (state) => {
        setDialogs({
            ...dialogs,
            open: state,
        });
    };

    const setButton = (state) => {
        setDialogs({
            ...dialogs,
            button: state,
        });
    };

    const noti = (title, content, button, size = 'sm') => {
        setDialogs({
            ...dialogs,
            size,
            title,
            content,
            button,
            open: true,
        });
    };

    return (
        <DialogsContext.Provider
            value={{
                ...dialogs,
                onChangeTitle,
                onChangeContent,
                onOpen,
                setButton,
                noti
            }}
        >
            {children}
            <Dialog
                fullWidth
                
                maxWidth={dialogs.size}
                open={dialogs.open}
                scroll='paper'
            // onClose={() => { onOpen(false) }}
            >
                {dialogs.title &&
                    <DialogTitle>
                        <Stack direction="column" alignItems="center" justifyContent="center">
                            <Typography variant="h5" > {dialogs.title}</Typography>
                        </Stack>
                    </DialogTitle>
                }
                {dialogs.content &&
                    <DialogContent>
                        <Stack sx={{ mt: 2 }} >
                            {
                                dialogs.content
                            }
                        </Stack>
                    </DialogContent>
                }
                <DialogActions sx={{ mt: 0 }}>
                    {
                        dialogs.button
                    }
                    {
                        !dialogs.button && <Button onClick={() => { onOpen(false) }}>CLOSE</Button>
                    }
                </DialogActions>
            </Dialog>
        </DialogsContext.Provider>
    );
}

export { DialogsProvider, DialogsContext }
