/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input'

// ----------------------------------------------------------------------

RHFPhoneField.propTypes = {
  name: PropTypes.string,
};

export default function RHFPhoneField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MuiTelInput
          {...field}
          label="Phone Number"
          focusOnSelectCountry
          // forceCallingCode
          // onlyCountries={["FR"]}
          helperText={fieldState.invalid ? "Tel is invalid" : ""}
          error={fieldState.invalid}
          MenuProps={{
            style: {
              maxHeight: 400,
            },
          }}
        />
      )}
    />
  );
}
